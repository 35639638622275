<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-11-09 13:50:38
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-30 18:48:15
 * @FilePath: /siana_admin/src/views/product/components/search.vue
-->
<template>
  <el-card class="mb20"
    shadow="never">
    <div slot="header"
      class="search_box">
      <div class="left">
        筛选条件
      </div>
      <div class="right">
        <el-button type="text"
          @click="phone = !phone">
          {{ phone?'展开':'收起' }}
          <i
            class="ml10 el-icon-arrow-up"></i>
        </el-button>
      </div>
    </div>

    <!-- 搜索开始 -->
    <el-form v-if="!phone"
      ref="form" :model="form"
      label-width="90px">
      <el-row :gutter="20">
        <!--表格col-->
        <el-col :xl="6"
          :lg="6" :md="8"
          :sm="12" :xs="24"
          style="margin-bottom: 10px;">
          <!-- <el-form-item
            style="margin-bottom: 0;"
            label="名称">
            <el-input
              v-model="form.name"
              style="width: 100%;"
              placeholder="请输入" /> -->
            <!-- 搜索 select -->
            <!-- 搜索 picker end-->
          <!-- </el-form-item> -->
           <el-form-item
            style="margin-bottom: 0;"
            label="错误类型">
            <el-select
              v-model="form.type"
              style="width:100%"
              @change="typeChange"
              placeholder="请选择">
              <el-option
                label="全部"
                :value="''" />
              <el-option
                label="异常信息"
                :value="1" />
              <el-option
                label="用户提示信息"
                :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <!--表格col-->
        <el-col :xl="18"
          :lg="18" :md="16"
          :sm="24" :xs="24"
          style="text-align: right;">
          <!-- 搜索 按钮 -->
          <el-button
            type="primary"
            @click="searchBtn">搜索</el-button>
          <el-button
            @click="repert">重置</el-button>
        </el-col>
        <!-- 搜索 按钮 -->
      </el-row>
    </el-form>
  </el-card>
  <!-- 表单结束 -->
</template>

<script>
// 引入组件及方法
export default {
  name: 'Yxb30uiSearch',
  props: ['columns'],
  // 数据
  data() {
    return {
      phone: false,
      showMore: true, // 查看更多
      form: {
        name: '',
        mobile: ''
      } // form对象
    }
  },

  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
  },
  methods: {
    // 点击搜索
    searchBtn() {
      this.$emit('search', this.form)
    },
    // 重置
    repert() {
      this.form.name = ''
      this.form.mobile = ''
      this.$emit('search', this.form)
    },
    typeChange(){
      this.$emit('search', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
.search_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
